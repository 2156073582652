import { Switch, Route, Redirect } from "react-router-dom"
import LandingPage from "./pages/LandingPage"
import LandingPageAr from "./pages/ar/LandingPage"
import TicketingPageAr from "./pages/ar/TicketingPage"
import WayfindingPageAr from "./pages/ar/WayfindingPage"
import ViewPageAr from "./pages/ar/ViewPage"
import RulesPageAr from "./pages/ar/RulesPage"
import EventsPage from "./pages/EventsPage"
import LoginPage from "./pages/auth/LoginPage"
import LogoutPage from "./pages/auth/LogoutPage"
import SignupPage from "./pages/auth/SignupPage"
import ProfilePage from "./pages/ProfilePage"
import AdminPage from "./pages/admin/Index"
import AdminEventSubscribersPage from "./pages/admin/EventSubscribersPage"
import AdminConfigPage from "./pages/admin/ConfigPage"
import AdminConfigEditPage from "./pages/admin/ConfigEditPage"
import StreamPage from "./pages/StreamPage"
import ProtectedRoute from "./components/ProtectedRoute"
import ModalAlert from "./components/UI/ModalAlert"
import "bootstrap/dist/css/bootstrap.css"

function App() {
    return (
        <>
            <ModalAlert />
            <Switch>
                <Route exact path="/" component={LandingPage}></Route>
                <Route exact path="/loaderio-0a6a8a877bf2a85f988b14d54c6102ae/"></Route>
                <Route exact path="/:sportId/events/" component={EventsPage}></Route>
                <Route exact path="/auth/login" component={LoginPage}></Route>
                <Route exact path="/auth/logout" component={LogoutPage}></Route>
                <Route exact path="/auth/signup" component={SignupPage}></Route>
                {/* admin pages */}
                <ProtectedRoute exact path="/profile" component={ProfilePage} />
                <ProtectedRoute exact adminAccess={true} path="/admin" component={AdminPage} />
                <ProtectedRoute exact adminAccess={true} path="/admin/:sportId/:eventStatus/" component={AdminConfigPage} />
                <ProtectedRoute exact adminAccess={true} path="/admin/:sportId/:eventStatus/:configId" component={AdminConfigEditPage} />
                <ProtectedRoute exact adminAccess={true} path="/admin/eventsubscribers" component={AdminEventSubscribersPage} />

                {/* ar pages */}
                <Route exact path="/:sportId/ar/" component={LandingPageAr} />
                <Route exact path="/:sportId/ar/ticketing" component={TicketingPageAr} />
                <Route exact path="/:sportId/ar/wayfinding" component={WayfindingPageAr} />
                <Route exact path="/:sportId/ar/rules" component={RulesPageAr} />
                <Route exact path="/:sportId/ar/view" component={ViewPageAr} />

                {/* 360 pages */}
                <Route exact path="/:sportId/demo/" component={StreamPage} />
                <Route exact path="/:sportId/:eventStatus/" component={StreamPage} />
                <ProtectedRoute exact path="/:sportId/:eventStatus/:configId" component={StreamPage} />

                <Route path="*">
                    <Redirect to="/" />
                </Route>
            </Switch>
        </>
    )
}

export default App
